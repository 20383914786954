import React from 'react';

export type StripesProps = {
  /**
   * the coloring for the first sript
   */
  colorFirst: string;
  /**
   * the coloring for the second sript
   */
  colorSecond: string;
};

export const Stripes: React.FC<StripesProps> = ({
  colorFirst = '#60BA94',
  colorSecond = '#DADC27',
}: StripesProps) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 17.8 14.5"
      >
        <polygon
          style={{ fill: colorFirst }}
          points="11.8,14.5 7.1,14.5 13.1,0 17.8,0 "
        />
        <polygon
          style={{ fill: colorSecond }}
          points="4.7,14.5 0,14.5 6,0 10.7,0 "
        />
      </svg>
    </>
  );
};
