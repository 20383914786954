import React from 'react';
import { Box } from '@mui/material';

export type HtmlContentProps = {
  /**
   * html content.
   */
  dangerouslySetInnerHTML: any;
  /**
   * styling of the html content.
   */
  style?: any;
};

export const HtmlContent: React.FC<HtmlContentProps> = ({
  dangerouslySetInnerHTML,
  style,
}: HtmlContentProps) => {
  return (
    <Box
      dangerouslySetInnerHTML={{ __html: dangerouslySetInnerHTML }}
      sx={style}
    />
  );
};
