import React from 'react';
import { Headline } from '../../../../components/cvt/data-display/headline';

import Box from '@mui/material/Box';

import { Stripes } from './stripes';

export type BulletListProps = {
  /**
   * A list consistent of headline and text to be rendered as a bullet-point.
   */
  list: { headline?: string; text?: string }[];
  /**
   * to align bullet points to left or right
   */
  alignBulletPoints?: 'left' | 'right';
  /**
   * the coloring for the first sript
   */
  colorFirst?: string;
  /**
   * the coloring for the second sript
   */
  colorSecond?: string;
};

export const BulletList: React.FC<BulletListProps> = ({
  list,
  alignBulletPoints = 'left',
  colorFirst = '#60BA94',
  colorSecond = '#DADC27',
}: BulletListProps) => {
  const styles = {
    container__root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: alignBulletPoints === 'left' ? 'flex-start' : 'flex-end',
    },
    container__content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: alignBulletPoints === 'left' ? 'flex-start' : 'flex-end',
      textAlign: alignBulletPoints === 'left' ? 'left' : 'right',
    },
    container__svg: {
      display: 'flex',
      height: '40px',
      width: '50px',
      margin: alignBulletPoints === 'left' ? '0 1.5em 0 0' : '0 0 0 1.5em',
    },
  } as const;

  const renderList = (list) => {
    return list.map((element, index) => {
      return (
        <Box sx={styles.container__root} key={`bulletlist-${index}`}>
          {alignBulletPoints === 'left' && (
            <Box sx={styles.container__svg}>
              <Stripes colorFirst={colorFirst} colorSecond={colorSecond} />
            </Box>
          )}
          <Box sx={styles.container__content}>
            {element.headline && (
              <Headline variant="h4" text={element.headline} />
            )}
            {element.text && <p>{element.text}</p>}
          </Box>
          {alignBulletPoints === 'right' && (
            <Box sx={styles.container__svg}>
              <Stripes colorFirst={colorFirst} colorSecond={colorSecond} />
            </Box>
          )}
        </Box>
      );
    });
  };
  return <>{renderList(list)}</>;
};
