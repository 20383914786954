import React from 'react';
import { Headline } from '../../../../components/cvt/data-display/headline';
import { CvtBasicTheme, CvtColorSchema } from '../../../../global/cvt-obj';
import { DiagonalDiv } from '../../../../components/cvt/layout/diagonal-div';
import { Box } from '@mui/material';

export type BlockTopProps = {
  /**
   * the headline's text.
   */
  headline: string;
  /**
   * the subheadline's text.
   */
  subheadline: string;
  /**
   * the headline's text color.
   */
  color: string;
  /**
   * the top-block padding.
   */
  padding: string;
  /**
   * the clip-path to set/align the diagonal.
   */
  diagonalAlignment: 'none' | 'leftEdgeLow' | 'leftEdgeHigh';
  /**
   * the necessary zIndex to overlap the divs.
   */
  zIndex: number;
  /**
   * to set a background image as url for the div.
   */
  bgImage?: string;
  /**
   * to set the position for the image.
   */
  bgImagePosition?: string;
};

export const BlockTop: React.FC<BlockTopProps> = ({
  headline,
  subheadline,
  color = CvtColorSchema.fontDarkGray,
  padding,
  diagonalAlignment = 'none',
  zIndex,
  bgImage,
  bgImagePosition,
}: BlockTopProps) => {
  const baseContainer = {
    display: 'flex',
    flexDirection: 'column',
  } as const;

  const styles = {
    root: {
      marginTop: '7em',
    },

    container__standard: {
      ...baseContainer,
      justifyContent: 'center',
      alignItems: 'center',
      widht: '100%',
    },
    container__text: {
      ...baseContainer,
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
  };
  return (
    <Box component="div" sx={styles.root}>
      <DiagonalDiv
        color={color}
        marginTop={CvtBasicTheme.margin.top}
        padding={padding}
        diagonalAlignment={diagonalAlignment}
        bgImage={bgImage}
        zIndex={zIndex}
        bgImagePosition={bgImagePosition}
        minHeight={16}
      >
        <Box component="div" sx={styles.container__standard}>
          <Box component="div" sx={styles.container__text}>
            <Headline variant="h1" text={headline} />
            <Headline variant="h3" text={subheadline} />
          </Box>
        </Box>
      </DiagonalDiv>
    </Box>
  );
};
